import {
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarExport,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

import FilterIconButton from "./FilterIconButton";
import { isEndUser } from "common/helpers/utils";
import { FilterDisplayOption } from "common/enums";
import ResetButton from "./ResetIconButton";

type CustomToolbarProps = {
  handleFilterClick: () => void;
  isDisplaySortOptions?: boolean;
  handleSortOptionChange?: (selectedOption: FilterDisplayOption) => void;
  sortOptionValue?: FilterDisplayOption;
  // isDevicesTable?: boolean;
  isShowReset?: boolean;
  handleResetClick?: () => void;
};

function CustomToolbar({
  handleFilterClick,
  isDisplaySortOptions = false,
  handleSortOptionChange,
  sortOptionValue = FilterDisplayOption.ALL,
  // isDevicesTable = false,
  isShowReset = false,
  handleResetClick,
}: CustomToolbarProps) {
  const handleOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    handleSortOptionChange?.(value as FilterDisplayOption);
  };

  const handleReset = () => {
    handleResetClick?.();
  };

  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        <GridToolbarQuickFilter color="info" sx={{ marginRight: 1 }} />
        <GridToolbarColumnsButton slotProps={{ button: { color: "info" } }} />
        <FilterIconButton handleFilterClick={handleFilterClick} />
        {!isEndUser() && (
          <GridToolbarExport slotProps={{ button: { color: "info" } }} />
        )}
        {isShowReset && (
          <ResetButton handleResetClick={handleReset}></ResetButton>
        )}
      </div>
      {isDisplaySortOptions && (
        <RadioGroup
          row
          name="filter-options"
          value={sortOptionValue}
          onChange={handleOptionChange}
          sx={{ color: (theme) => theme.palette.info.main }}
        >
          <FormControlLabel
            value={FilterDisplayOption.FAVORITE}
            control={
              <Radio
                size="small"
                sx={{ color: (theme) => theme.palette.info.main }}
              />
            }
            label={<Typography variant="body2">Favorites</Typography>}
          />
          <FormControlLabel
            value={FilterDisplayOption.RECENT}
            control={
              <Radio
                size="small"
                sx={{ color: (theme) => theme.palette.info.main }}
              />
            }
            label={<Typography variant="body2">Recent</Typography>}
          />
          <FormControlLabel
            value={FilterDisplayOption.ALL}
            control={
              <Radio
                size="small"
                sx={{ color: (theme) => theme.palette.info.main }}
              />
            }
            label={<Typography variant="body2">All</Typography>}
          />
        </RadioGroup>
      )}
    </GridToolbarContainer>
  );
}

export default CustomToolbar;
