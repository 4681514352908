import React, { useCallback, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CssBaseline,
  Grid,
  Typography,
} from "@mui/material";

import { useFilterFormStyles } from "common/styles/useFilterFormStyles";
import TcCheckboxInput from "common/components/TcCheckboxInput";
import { RuleStatus } from "common/enums";
import { FormMasterData, RuleFilter } from "../types";
import { constants } from "common/constants";

const ruleStatuses = [
  { id: RuleStatus.ACTIVE, label: RuleStatus.ACTIVE },
  { id: RuleStatus.FUTURE, label: RuleStatus.FUTURE },
  { id: RuleStatus.DISABLED, label: RuleStatus.DISABLED },
  { id: RuleStatus.EXPIRED, label: RuleStatus.EXPIRED },
  { id: RuleStatus.INCOMPLETE, label: RuleStatus.INCOMPLETE },
];

const accessProfiles = constants.ACCESS_PROFILES.map((profile) => {
  return { id: profile, label: profile };
});

type FilterProps = {
  onApplyFilter: (formData: RuleFilter) => void;
  filters: RuleFilter;
  handleClearFilter: () => void;
  handleCancelFilter: () => void;
  formMasterData: FormMasterData;
  isShowCancel?: boolean;
  isResetForm?: boolean;
};

const RulesFilter: React.FC<FilterProps> = (filterProps) => {
  const classes = useFilterFormStyles();
  const { deviceGroups, userGroups } = filterProps.formMasterData;
  const { isShowCancel = true } = filterProps;
  const [statuses] = useState<
    {
      id: string;
      label: string;
    }[]
  >(ruleStatuses);
  const [profiles] = useState<{ id: string; label: string }[]>(accessProfiles);
  const methods = useForm({
    defaultValues: filterProps.filters,
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = handleSubmit(async (data: RuleFilter) => {
    filterProps.onApplyFilter(data);
  });

  const resetForm = useCallback(() => {
    reset();
  }, [reset]);

  const onClear = () => {
    resetForm();
    filterProps.handleClearFilter();
  };

  const onCancel = () => {
    resetForm();
    filterProps.handleCancelFilter();
  };

  useEffect(() => {
    if (filterProps.isResetForm) {
      resetForm();
    }
  }, [filterProps.isResetForm, resetForm]);

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.root}>
        <FormProvider {...methods}>
          <Card key={1} className={classes.longCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Device Groups</strong>
                </Typography>
              }
              className={classes.cardHeader}
            ></CardHeader>
            <CardContent className={classes.cardContent}>
              <TcCheckboxInput
                name="devicesGroups"
                optionKey="groupId"
                optionLabel="name"
                options={deviceGroups}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Card key={2} className={classes.longCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>User Groups</strong>
                </Typography>
              }
              className={classes.cardHeader}
            ></CardHeader>
            <CardContent className={classes.cardContent}>
              <TcCheckboxInput
                name="userGroups"
                optionKey="groupId"
                optionLabel="name"
                options={userGroups}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Card key={3} className={classes.longCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Local Access Profile</strong>
                </Typography>
              }
              className={classes.cardHeader}
            ></CardHeader>
            <CardContent>
              <TcCheckboxInput
                name="accessProfiles"
                optionKey="id"
                optionLabel="label"
                options={profiles}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>

          <Card key={4} className={classes.shortCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Status</strong>
                </Typography>
              }
              className={classes.cardHeader}
            ></CardHeader>
            <CardContent>
              <TcCheckboxInput
                name="statuses"
                optionKey="id"
                optionLabel="label"
                options={statuses}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Grid container spacing={2} className={classes.bottomDivider}>
            <Grid item xs={2}>
              <Button
                size="small"
                type="submit"
                onClick={onSubmit}
                fullWidth
                variant="contained"
                color="info"
              >
                {"Apply All"}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="info"
                onClick={onClear}
              >
                {"Clear All"}
              </Button>
            </Grid>
            {isShowCancel && (
              <Grid item xs={2}>
                <Button
                  size="small"
                  fullWidth
                  variant="outlined"
                  color="info"
                  onClick={onCancel}
                >
                  {"Cancel"}
                </Button>
              </Grid>
            )}
          </Grid>
        </FormProvider>
      </div>
    </React.Fragment>
  );
};
export default RulesFilter;
