import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CssBaseline,
  Card,
  Link,
  Typography,
  CardHeader,
  Button,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useContentStyles } from "common/styles/useContentStyles";
import { defaultValues } from "./AddRule";
import { useDispatch } from "react-redux";
import apiClient from "common/apiClientAxios";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import { FormMasterData, Rule } from "../types";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import RulesTable from "./RulesTable";
import DeleteRule from "./DeleteRule";
import { useNavigate } from "react-router-dom";
import { getTimezone, hasPermission } from "common/helpers/utils";
import { constants } from "common/constants";

const Rules: React.FC = () => {
  const classes = useContentStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isUpdate, setUpdate] = useState(true);
  const [selectedRule, setSelectedRule] = useState<Rule>({
    ...defaultValues,
    ruleId: "",
    timezone: "",
  });
  const [openDialogue, setOpenDialogue] = React.useState(false);
  const [rules, setRules] = useState<Rule[]>([]);

  const updateTable = useCallback(async () => {
    const timezone = getTimezone();
    try {
      const rulesResponse = await apiClient.get(
        `/trust-rules?timezone=${timezone}`
      );
      setRules(rulesResponse.data.data as Rule[]);
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (isUpdate) {
      updateTable();
      setUpdate(false);
    }
  }, [isUpdate, updateTable]);

  //on click of add zone
  const openAddForm = () => {
    //setOpenForm(true);
    navigate(`/portal/trust/list/new`, {
      state: { formMasterData },
    });
  };

  const onDeleteRule = async () => {
    try {
      if (selectedRule.ruleId) {
        const zoneDeleteResponse = await apiClient.delete(
          `/trust-rules/${selectedRule.ruleId}`
        );
        dispatch(
          setSnackbarToast({
            message: zoneDeleteResponse.data.meta.message,
            open: true,
            severity: "success",
          })
        );
        onDeleteUpdateTable();
      }
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
      setOpenDialogue(false);
    }
  };

  const handleDeleteRule = (ruleObj: Rule) => {
    setSelectedRule(ruleObj);
    setOpenDialogue(true);
  };

  const onDeleteUpdateTable = () => {
    setOpenDialogue(false);
    setUpdate(true);
  };

  const onCancelRemove = () => {
    setOpenDialogue(false);
  };

  const handleEditRule = (ruleObj: Rule) => {
    navigate(`/portal/trust/list/edit`, {
      state: { formMasterData, selectedRule: ruleObj },
    });
  };

  const [formMasterData, setFormMasterData] = useState<FormMasterData>({
    userGroups: [],
    deviceGroups: [],
    devices: [],
    users: [],
    zones: [],
    rules: [],
  });
  const isRunEffect = useRef(true);

  const getFormMasterData = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const formMasterResponse = await apiClient.get(
        `trust-rules/form/look-up-data`
      );
      const formMasters = formMasterResponse.data.data as FormMasterData;
      setFormMasterData(formMasters);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (isRunEffect.current) {
      getFormMasterData();
      isRunEffect.current = false;
    }
  }, [getFormMasterData, isRunEffect]);

  const handleFavoriteRule = async (rule: Rule) => {
    const timezone = getTimezone();
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      if (rule.isFavorite) {
        await apiClient.delete(`users/favoriteRule/${rule.ruleId}`);
      } else {
        await apiClient.put(`users/favoriteRule/${rule.ruleId}`);
      }
      const rulesResponse = await apiClient.get(
        `/trust-rules?timezone=${timezone}`
      );
      setRules(rulesResponse.data.data as Rule[]);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  };

  const changeRuleStatus = async (rule: Rule) => {
    const timezone = getTimezone();
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      await apiClient.put(`trust-rules/${rule.ruleId}`, {
        ...rule,
        timezone: getTimezone(),
      });
      const rulesResponse = await apiClient.get(
        `/trust-rules?timezone=${timezone}`
      );
      setRules(rulesResponse.data.data as Rule[]);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="none" color="inherit">
            Trust
          </Link>
          <Typography variant="body1">{"List"}</Typography>
        </Breadcrumbs>
      </div>
      <Card elevation={0} className={classes.contentSection}>
        <CardHeader
          disableTypography
          title="Trust List"
          className={classes.cardHeader}
          action={
            <Button
              onClick={openAddForm}
              color="info"
              disabled={!hasPermission("trust.list", "write")}
              startIcon={<PlaylistAddIcon />}
            >
              {"Add Rule"}
            </Button>
          }
        ></CardHeader>
        {openDialogue && (
          <DeleteRule
            onDeleteRule={onDeleteRule}
            selectedRule={selectedRule}
            open={openDialogue}
            onCancelRemove={onCancelRemove}
          />
        )}
        <RulesTable
          data={rules}
          handleDeleteRule={handleDeleteRule}
          handleEditRule={handleEditRule}
          handleFavoriteRule={handleFavoriteRule}
          formMasterData={formMasterData}
          changeRuleStatus={changeRuleStatus}
        />
      </Card>
    </React.Fragment>
  );
};

export default Rules;
