import React, { useEffect, useState } from "react";
import { CssBaseline, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import GroupAdd from "@mui/icons-material/GroupAdd";

import apiClient from "common/apiClientAxios";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import {
  Device,
  DeviceFormMasterData,
  DeviceForm,
  DeviceGroup,
} from "../types";
import { constants } from "../../../common/constants";
import { useDrawerFormStyles } from "common/styles/useDrawerFormStyles";
import TcSelectDropdown from "common/components/TcSelectDropdown";
import TcTextField from "common/components/TcTextField";
import { DeviceAccessMethod, DeviceGroupType } from "common/enums";
import { AccessMethod } from "pages/users/types";
import FormHeader from "common/components/FormHeader";
import SelectGroupDialog from "./SelectGroupDialog";
import { filterDeviceGroup, hasAllElements } from "common/helpers/utils";
import TcSelectWithButtonLastOption from "common/components/TcSelectWithButtonLastOption";

type EditDeviceProps = {
  onClose: () => void;
  onSaveUpdateTable: () => void;
  selectedDevice: Device;
  deviceFormMasterData: DeviceFormMasterData;
  openAddManufacturerForm: () => void;
};

const EditDevice: React.FC<EditDeviceProps> = (props) => {
  const classes = useDrawerFormStyles();
  const dispatch = useDispatch();
  const { groups, deviceTypes, manufacturers, sites, accessMethods } =
    props.deviceFormMasterData;
  const onClose = () => {
    props.onClose();
  };

  const methods = useForm<DeviceForm>({
    defaultValues: {
      ...props.selectedDevice,
      groups: props.selectedDevice.groups?.map((obj) => obj.groupId),
      connections: props.selectedDevice.connections?.map((obj) => obj.deviceId),
    },
    mode: "onBlur",
  });
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isValid, isDirty, dirtyFields },
  } = methods;

  const [affectedDynamicGroups, setAffectedDynamicGroups] = useState<
    DeviceGroup[]
  >([]);
  const [submittedDeviceFormData, setSubmittedDeviceFormData] =
    useState<DeviceForm>({
      ...props.selectedDevice,
      groups: props.selectedDevice.groups?.map((obj) => obj.groupId),
      connections: props.selectedDevice.connections?.map((obj) => obj.deviceId),
    });
  const [openSelectGroupDialog, setOpenSelectGroupDialog] = useState(false);

  const onUpdateDevice = handleSubmit(async (data: DeviceForm) => {
    setSubmittedDeviceFormData(data);
    const dynamicGroups = groups.filter(
      (group) => group.type === DeviceGroupType.DYNAMIC
    );
    //if affected group is same as existing device group then submit form
    const dynamicGroupIds = dynamicGroups.map((grp) => grp.groupId);
    const defaultGroupIds = props.selectedDevice.groups?.map(
      (grp) => grp.groupId
    );
    if (
      dynamicGroupIds.length === defaultGroupIds?.length &&
      hasAllElements(dynamicGroupIds, defaultGroupIds)
    ) {
      submitDevice(defaultGroupIds, data);
    } else {
      const filterGroups = filterDeviceGroup(dynamicGroups, data);
      setAffectedDynamicGroups(filterGroups);
      if (filterGroups.length === 0) {
        submitDevice([], data);
      } else {
        setOpenSelectGroupDialog(true);
      }
    }
  });

  const submitDevice = async (selectedGroups?: string[], data?: DeviceForm) => {
    try {
      const formData = data ?? submittedDeviceFormData;
      const deviceGroups = groups
        .filter((group) => selectedGroups?.includes(group.groupId))
        .map((obj) => {
          return { groupId: obj.groupId, name: obj.name };
        });
      const selectedType = deviceTypes.find(
        (deviceType) => deviceType.deviceTypeId === formData.typeId
      );
      const selectedManufacturer = manufacturers.find(
        (manufacturer) =>
          manufacturer.manufacturerId === formData.manufacturerId
      );
      const type = selectedType?.name;
      const manufacturerName = selectedManufacturer?.name;
      const site = sites.find((site) => site.siteId === formData.siteId);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const deviceResponse = await apiClient.put(
        `/devices/${props.selectedDevice.deviceId}`,
        {
          ...formData,
          groups: deviceGroups,
          type,
          manufacturerName,
          siteName: site?.name,
          inboundIpAddr: site?.inboundIpAddr,
        }
      );
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      dispatch(
        setSnackbarToast({
          message: deviceResponse.data.meta.message,
          open: true,
          severity: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
    props.onSaveUpdateTable();
  };

  const watchedTypeValue = watch("typeId");
  const [formAccessMethods, setFormAccessMethods] =
    useState<AccessMethod[]>(accessMethods);

  useEffect(() => {
    if (watchedTypeValue) {
      dirtyFields.typeId && setValue("accessMethod", "");
      const type = deviceTypes.find(
        (deviceType) => deviceType.deviceTypeId === watchedTypeValue
      )?.name;
      if (type?.toLocaleLowerCase() !== constants.DEVICE_TYPE_GATEWAY) {
        setFormAccessMethods(
          accessMethods.filter(
            (method) =>
              method.name.toLocaleLowerCase() !== DeviceAccessMethod.CONFIG
          )
        ); //remove config
      } else {
        setFormAccessMethods(
          accessMethods.filter(
            (method) =>
              method.name.toLocaleLowerCase() === DeviceAccessMethod.CONFIG
          )
        );
      }
    }
  }, [
    accessMethods,
    deviceTypes,
    dirtyFields.typeId,
    setValue,
    watchedTypeValue,
  ]);

  const handleCreateManufacturer = () => {
    props.openAddManufacturerForm();
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <FormHeader
        title={`Edit Device: ${props.selectedDevice.name}`}
        onClose={onClose}
      ></FormHeader>
      <FormProvider {...methods}>
        <form noValidate>
          <Grid container spacing={2} className={classes.formContainer}>
            <Grid item xs={6}>
              <TcTextField
                name="name"
                label="Name *"
                rules={{
                  required: "Name is required",
                  pattern: {
                    value: constants.NAME_REGEX,
                    message: "Invalid name",
                  },
                }}
              ></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="alias"
                label="Alias"
                rules={{
                  pattern: {
                    value: constants.NAME_REGEX,
                    message: "Invalid Alias",
                  },
                }}
              ></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcSelectWithButtonLastOption
                name="manufacturerId"
                label="Manufacturer"
                optionKey="manufacturerId"
                options={manufacturers}
                optionLabel="name"
                buttonIcon={<GroupAdd />}
                buttonLabel="Add Manufacturer"
                optionButtonClick={handleCreateManufacturer}
              ></TcSelectWithButtonLastOption>
            </Grid>
            <Grid item xs={6}>
              <TcSelectDropdown
                name="siteId"
                label="Site *"
                options={sites}
                optionKey="siteId"
                optionLabel="name"
                rules={{
                  required: "Site is required",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="ipAddress"
                label="Local IP Address *"
                rules={{
                  required: "Local IP Address is required",
                  pattern: {
                    value: constants.IP_ADDRESS_REGEX,
                    message: "Invalid IP",
                  },
                }}
              ></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcSelectDropdown
                name="typeId"
                label="Type *"
                options={deviceTypes}
                optionKey="deviceTypeId"
                optionLabel="name"
                rules={{
                  required: "Type is required",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcSelectDropdown
                name="accessMethod"
                label="Access Method *"
                options={formAccessMethods}
                optionKey="name"
                optionLabel="name"
                rules={{
                  required: "Access Method is required",
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.bottomDivider}>
            <Grid item xs={2}>
              <Button
                size="small"
                type="submit"
                disabled={!isValid || !isDirty}
                onClick={onUpdateDevice}
                fullWidth
                variant="contained"
                color="info"
              >
                {"Save"}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="info"
                onClick={onClose}
              >
                {"Cancel"}
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </form>
      </FormProvider>
      {openSelectGroupDialog && (
        <SelectGroupDialog
          groups={affectedDynamicGroups}
          onCancel={() => setOpenSelectGroupDialog(false)}
          onSaveGroup={(selectedGroups) => submitDevice(selectedGroups)}
          open={openSelectGroupDialog}
        ></SelectGroupDialog>
      )}
    </React.Fragment>
  );
};

export default EditDevice;
