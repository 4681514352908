import React from "react";
import { Controller, useFormContext, Control } from "react-hook-form";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";

interface TcSelectWithStringArrayProps {
  name: string;
  label: string;
  options: string[];
  rules?: Record<string, any>;
  multiple?: boolean;
  defaultValue?: string | number;
  disabled?: boolean;
}

const TcSelectWithStringArray = ({
  name,
  label,
  options,
  rules,
  multiple = false,
  defaultValue = "",
  disabled = false,
}: TcSelectWithStringArrayProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const isError = Boolean(errors[name]);

  return (
    <FormControl fullWidth variant="outlined" error={Boolean(errors[name])}>
      <InputLabel size="small">{label}</InputLabel>
      <Controller
        name={name}
        control={control as Control}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Select
            data-testid={name}
            label={label}
            size="small"
            value={field.value}
            onChange={field.onChange}
            disabled={disabled}
            multiple={multiple}
          >
            {options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {isError && (
        <FormHelperText>
          {errors[name] ? (errors[name]?.message as unknown as string) : ""}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default TcSelectWithStringArray;
