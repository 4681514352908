import { useCallback, useState, useEffect, useMemo } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import Link from "@mui/material/Link";
import { Theme } from "@mui/material/styles";

import { Device, DeviceGroup } from "../types";
import { constants } from "common/constants";
import { useContentStyles } from "common/styles/useContentStyles";
import {
  DeviceAccessMethod,
  FeatureStatus,
  RemoteAccessType,
} from "common/enums";
import apiClient from "common/apiClientAxios";
import { hasPermission, isEndUser } from "common/helpers/utils";
import { FeatureSetting } from "pages/settings/types";
import assets from "../../../assets";
import VideoModal from "common/components/VideoModal";
import RemoteAccessSSH from "../remoteAccess/RemoteAccessSSH";
import RemoteAccessRDP from "../remoteAccess/RemoteAccessRDP";
import NoRowsOverlay from "common/components/NoRowsOverlay";
import RemoteAccessVNC from "../remoteAccess/RemoteAccessVNC";
import { setSnackbarToast } from "redux/UiStateSlice";
import { User } from "pages/users/types";

type DevicesTableProps = {
  data: Device[];
  remoteAccessType?: RemoteAccessType;
};

const deviceDefaultValues = {
  siteId: "",
  name: "",
  alias: "",
  latitude: "",
  longitude: "",
  deviceId: "",
  manufacturerId: "",
  interfaces: [],
  connections: [],
  type: "",
  typeId: "",
  groups: [],
  siteName: "",
  ipAddress: "",
  accessMethods: [],
  accessMethod: "",
  manufacturerName: "",
  status: "",
  macAddress: "",
  inboundIpAddr: "",
};

const GroupDevicesSubTable: React.FC<DevicesTableProps> = (props) => {
  const classes = useContentStyles();
  const dispatch = useDispatch();
  const [rows, setRows] = useState<Device[]>(props.data);
  const [selectedDevice, setSelectedDevice] =
    useState<Device>(deviceDefaultValues);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [openSSHAccessModal, setOpenSSHAccessModal] = useState(false);
  const [openRDPAccessModal, setOpenRDPAccessModal] = useState(false);
  const [openVNCAccessModal, setOpenVNCAccessModal] = useState(false);

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };
  const handleCloseSSHAccessModal = () => {
    setOpenSSHAccessModal(false);
  };
  const handleCloseRDPAccessModal = () => {
    setOpenRDPAccessModal(false);
  };
  const handleCloseVNCAccessModal = () => {
    setOpenVNCAccessModal(false);
  };

  const handleRemoteAccessType = useCallback(
    (accessMethod: string, device: Device) => {
      const loggedInUser = JSON.parse(
        localStorage.getItem("user") ?? "{}"
      ) as User;
      const remoteAccessType =
        (loggedInUser?.deviceAccessPreference as RemoteAccessType) ??
        RemoteAccessType.DIALOG;
      if (remoteAccessType !== RemoteAccessType.DIALOG) {
        localStorage.setItem("remote_access", JSON.stringify(device));
        openNewTab(accessMethod, device);
      } else {
        setSelectedDevice(device);
        handleDialogAccess(accessMethod);
      }
    },
    []
  );

  const openNewTab = (accessMethod: string, device: Device) => {
    const url = `/devices/remoteAccess/${device.deviceId}/${accessMethod}`;
    const newTab = window.open(url, "_blank");
    if (newTab) {
      newTab.focus();
    } else {
      console.error(
        "Unable to open a new tab. Make sure pop-ups are allowed in the browser."
      );
    }
  };

  const handleDialogAccess = (accessMethod: string) => {
    switch (accessMethod.toLocaleLowerCase()) {
      case DeviceAccessMethod.SSH:
        setOpenSSHAccessModal(true);
        break;
      case DeviceAccessMethod.RDP:
        setOpenRDPAccessModal(true);
        break;
      case DeviceAccessMethod.HTTP:
      case DeviceAccessMethod.HTTPS:
        setOpenVNCAccessModal(true);
        break;
      default:
        break;
    }
  };

  const handleLocalAccess = (accessMethod: string) => {
    setVideoUrl(
      accessMethod === DeviceAccessMethod.SSH
        ? assets.videos.SSHVideo
        : assets.videos.RDPSCADA
    );
    setOpenVideoModal(true);
  };

  const handleAccessMethodClick = useCallback(
    async (accessMethod: string, device: Device) => {
      //If feature is code_devices.remote_access, then show access modal else show video modal
      const featuresResponse = await apiClient.get("/settings/features");
      const featureList = featuresResponse.data.data as FeatureSetting[];
      const remoteAccessSettings = featureList.find(
        (feature) => feature.featureId === "code_devices.remote_access"
      );

      if (remoteAccessSettings?.status === FeatureStatus.ENABLED) {
        if (!device.siteId) {
          dispatch(
            setSnackbarToast({
              message: constants.DEVICE_SITE_MISSING,
              open: true,
              severity: "error",
            })
          );
        } else {
          handleRemoteAccessType(accessMethod, device);
        }
      } else {
        handleLocalAccess(accessMethod);
      }
    },
    [dispatch, handleRemoteAccessType]
  );

  useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "name",
        type: "string",
        headerName: "Device Name",
        // flex: 1,
        width: 250,
      },
      {
        field: "alias",
        type: "string",
        headerName: "Alias(es)",
        flex: 1,
      },
      {
        field: "groups",
        type: "string",
        headerName: "Group(s)",
        flex: 1,
        valueGetter: (value) => {
          const groups = (value || []) as unknown as DeviceGroup[];
          return groups.map((obj) => obj.name).join(", ");
        },
      },
      {
        field: "siteName",
        type: "string",
        headerName: "Site",
        flex: 1,
      },
      { field: "type", type: "string", headerName: "Type", flex: 1 },
      {
        field: "accessMethod",
        type: "string",
        headerName: "Access Method",
        flex: 1,
        renderCell: (params) => (
          <Link
            component="button"
            sx={{
              color: (theme: Theme) => theme.palette.info.main,
              textDecorationColor: (theme: Theme) => theme.palette.info.main,
            }}
            onClick={() =>
              handleAccessMethodClick(params.row.accessMethod, params.row)
            }
          >
            {params.row.accessMethod}
          </Link>
        ),
      },
      {
        field: "manufacturerName",
        type: "string",
        headerName: "Manufacturer",
        flex: 1,
      },
    ],
    [handleAccessMethodClick]
  );
  // Filter columns based on user role
  const filteredColumns = isEndUser()
    ? columns.filter((column) => column.field !== "groups")
    : columns;

  return (
    <>
      <DataGrid
        disableVirtualization
        columns={filteredColumns}
        rows={rows}
        getRowId={(row) => row.deviceId}
        hideFooter={true}
        hideFooterPagination={true}
        autoHeight
        slots={{
          noRowsOverlay: () => (
            <NoRowsOverlay
              hasAccess={hasPermission("devices.summary", "read")}
              name="Devices"
            />
          ),
        }}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "600",
          },
        }}
        getRowClassName={(params) =>
          params.row.type.toLowerCase() === constants.DEVICE_TYPE_GATEWAY
            ? classes.rowColor
            : ""
        }
      />
      {openSSHAccessModal && (
        <RemoteAccessSSH
          open={openSSHAccessModal}
          onClose={handleCloseSSHAccessModal}
          device={selectedDevice}
        />
      )}
      {openRDPAccessModal && (
        <RemoteAccessRDP
          open={openRDPAccessModal}
          onClose={handleCloseRDPAccessModal}
          device={selectedDevice}
        />
      )}
      {openVNCAccessModal && (
        <RemoteAccessVNC
          open={openVNCAccessModal}
          onClose={handleCloseVNCAccessModal}
          device={selectedDevice}
        />
      )}
      <VideoModal
        open={openVideoModal}
        onClose={handleCloseVideoModal}
        videoUrl={videoUrl}
      />
    </>
  );
};
export default GroupDevicesSubTable;
