import React, { useCallback, useState, useEffect, useMemo } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";

import {
  CreateDeviceGroup,
  Device,
  DeviceFormMasterData,
  DeviceGroup,
  DeviceGroupNameForm,
  DeviceRule,
  DevicesGroupFilterData,
} from "../types";
import DevicesFilter from "./DevicesFilter";
import { constants } from "common/constants";
import GatewayDeviceDetails from "../deviceDetails/GatewayDeviceDetails";
import { deviceDefaultValues } from "./Devices";
import { useContentStyles } from "common/styles/useContentStyles";
import CustomToolbar from "common/components/CustomToolbar";
import AddDeviceGroup from "../groups/AddDeviceGroup";
import {
  DeviceAccessMethod,
  DeviceGroupType,
  FeatureStatus,
  FilterDisplayOption,
  RemoteAccessType,
  RuleStatus,
} from "common/enums";
import DeviceTypeDialog from "../groups/DeviceTypeDialog";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import apiClient from "common/apiClientAxios";
import {
  getFilteredDeviceRows,
  hasPermission,
  sortDevicesByAccessStatus,
  sortRows,
} from "common/helpers/utils";
import { FeatureSetting } from "pages/settings/types";
import assets from "../../../assets";
import RemoteAccessSSH from "../remoteAccess/RemoteAccessSSH";
import RemoteAccessRDP from "../remoteAccess/RemoteAccessRDP";
import VideoModal from "common/components/VideoModal";
import GroupType from "./GroupType";
import { isEndUser } from "../../../common/helpers/utils";
import NoRowsOverlay from "common/components/NoRowsOverlay";
import RemoteAccessVNC from "../remoteAccess/RemoteAccessVNC";
import { User } from "pages/users/types";
import { splitByBreakTag } from "pages/trust/rules/AddRule";

type DevicesTableProps = {
  data: Device[];
  handleEditDevice: (device: Device) => void;
  handleDeleteDevice: (device: Device) => void;
  deviceFormMasterData: DeviceFormMasterData;
  showAddGroupForm: boolean;
  hideGroupForm: () => void;
  navigateToGroups: () => void;
  handleFavoriteDevice: (device: Device) => void;
  remoteAccessType?: RemoteAccessType;
};

const DevicesTable: React.FC<DevicesTableProps> = (props) => {
  const classes = useContentStyles();
  const [rows, setRows] = useState<Device[]>(
    isEndUser()
      ? props.data.filter(
          (dev) => dev.type.toLowerCase() !== constants.DEVICE_TYPE_GATEWAY
        )
      : props.data
  );
  const [filteredRows, setFilteredRows] = useState<Device[]>(
    isEndUser()
      ? props.data.filter(
          (dev) => dev.type.toLowerCase() !== constants.DEVICE_TYPE_GATEWAY
        )
      : props.data
  );

  const navigate = useNavigate();
  const [deviceGroupName, setDeviceGroupName] = useState("");
  const dispatch = useDispatch();
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedDevice, setSelectedDevice] =
    useState<Device>(deviceDefaultValues);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [openSSHAccessModal, setOpenSSHAccessModal] = useState(false);
  const [openRDPAccessModal, setOpenRDPAccessModal] = useState(false);
  const [openVNCAccessModal, setOpenVNCAccessModal] = useState(false);
  const [isFilterSubmitted, setFilterSubmitted] = useState(false);

  const [openFilter, setOpenFilter] = useState(false);
  const defaultFilterValues = useMemo(
    () => ({
      accessMethods: [],
      manufacturers: [],
      types: [],
      statuses: [],
      sites: [],
      interfaces: [],
      groups: [],
    }),
    []
  );
  const [filters, setFilters] =
    useState<DevicesGroupFilterData>(defaultFilterValues);
  const [selectedSortOption, setSelectedSortOption] = React.useState(
    FilterDisplayOption.ALL
  );

  const getSortedRows = React.useCallback(
    (inputRows: Device[], selectedValue?: FilterDisplayOption) => {
      if (selectedValue === FilterDisplayOption.ACCESS) {
        const sortedDevices = [...inputRows].sort(sortDevicesByAccessStatus);
        setRows(sortedDevices);
      } else {
        const records = sortRows([...inputRows], selectedValue) as Device[];
        setRows(records);
      }
    },
    []
  );

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };
  const handleCloseSSHAccessModal = () => {
    setOpenSSHAccessModal(false);
  };
  const handleCloseRDPAccessModal = () => {
    setOpenRDPAccessModal(false);
  };
  const handleCloseVNCAccessModal = () => {
    setOpenVNCAccessModal(false);
  };

  const handleRemoteAccessType = useCallback(
    (accessMethod: string, device: Device) => {
      const loggedInUser = JSON.parse(
        localStorage.getItem("user") ?? "{}"
      ) as User;
      const remoteAccessType =
        (loggedInUser?.deviceAccessPreference as RemoteAccessType) ??
        RemoteAccessType.DIALOG;
      if (remoteAccessType !== RemoteAccessType.DIALOG) {
        localStorage.setItem("remote_access", JSON.stringify(device));
        openNewTab(accessMethod, device);
      } else {
        setSelectedDevice(device);
        handleDialogAccess(accessMethod);
      }
    },
    []
  );

  const openNewTab = (accessMethod: string, device: Device) => {
    const url = `/devices/remoteAccess/${device.deviceId}/${accessMethod}`;
    const newTab = window.open(url, "_blank");
    if (newTab) {
      newTab.focus();
    } else {
      console.error(
        "Unable to open a new tab. Make sure pop-ups are allowed in the browser."
      );
    }
  };

  const handleDialogAccess = (accessMethod: string) => {
    switch (accessMethod.toLocaleLowerCase()) {
      case DeviceAccessMethod.SSH:
        setOpenSSHAccessModal(true);
        break;
      case DeviceAccessMethod.RDP:
        setOpenRDPAccessModal(true);
        break;
      case DeviceAccessMethod.HTTP:
      case DeviceAccessMethod.HTTPS:
        setOpenVNCAccessModal(true);
        break;
      default:
        break;
    }
  };

  const handleLocalAccess = (accessMethod: string) => {
    setVideoUrl(
      accessMethod === DeviceAccessMethod.SSH
        ? assets.videos.SSHVideo
        : assets.videos.RDPSCADA
    );
    setOpenVideoModal(true);
  };

  const handleAccessMethodClick = useCallback(
    async (accessMethod: string, device: Device) => {
      //If feature is code_devices.remote_access, then show access modal else show video modal
      const featuresResponse = await apiClient.get("/settings/features");
      const featureList = featuresResponse.data.data as FeatureSetting[];
      const remoteAccessSettings = featureList.find(
        (feature) => feature.featureId === "code_devices.remote_access"
      );
      if (remoteAccessSettings?.status === FeatureStatus.ENABLED) {
        if (!device.siteId) {
          dispatch(
            setSnackbarToast({
              message: constants.DEVICE_SITE_MISSING,
              open: true,
              severity: "error",
            })
          );
        } else handleRemoteAccessType(accessMethod, device);
      } else {
        handleLocalAccess(accessMethod);
      }
    },
    [dispatch, handleRemoteAccessType]
  );

  useEffect(() => {
    setSelectedSortOption(FilterDisplayOption.ALL);
    setFilteredRows(props.data);
    getSortedRows(props.data);
  }, [getSortedRows, props.data]);

  const editDevice = useCallback(
    (gridRow: any) => () => {
      props.handleEditDevice(gridRow.row);
    },
    [props]
  );

  const deleteDevice = useCallback(
    (gridRow: any) => () => {
      props.handleDeleteDevice(gridRow.row);
    },
    [props]
  );
  const handleNameClick = useCallback(
    (device: Device) => {
      if (
        device.type &&
        device.type.toLowerCase() === constants.DEVICE_TYPE_GATEWAY
      ) {
        setSelectedDevice(device);
        setOpenDetailsModal(true);
      } else {
        navigate(`/portal/devices/summary/${device.deviceId}`, {
          state: {
            device: device,
            deviceFormMasterData: props.deviceFormMasterData,
            remoteAccessType: props.remoteAccessType,
          },
        });
      }
    },
    [navigate, props.deviceFormMasterData, props.remoteAccessType]
  );

  const handleFavoriteClick = React.useCallback(
    (device: Device) => {
      props.handleFavoriteDevice(device);
    },
    [props]
  );

  const renderRuleAccessWindowCellContent = (rules: DeviceRule[]) => {
    if (rules.length === 0) {
      return "-";
    }

    const firstRule = rules[0];
    const { status, accessWindow } = firstRule;
    switch (status) {
      case RuleStatus.INCOMPLETE:
      case RuleStatus.DISABLED:
        return "-";
      default:
        return <div>{splitByBreakTag(accessWindow)}</div>;
    }
  };

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "name",
        type: "string",
        headerName: "Device Name",
        // flex: 1,
        width: 250,
        renderCell: (params) =>
          isEndUser() ? (
            <>
              <IconButton
                disabled={props.showAddGroupForm}
                onClick={() => handleFavoriteClick(params.row)}
                sx={{ paddingLeft: 0 }}
              >
                {params.row.isFavorite ? (
                  <StarIcon color="secondary" />
                ) : (
                  <StarOutlineIcon />
                )}
              </IconButton>
              {params.row.name}
            </>
          ) : (
            <>
              <IconButton
                disabled={props.showAddGroupForm}
                onClick={() => handleFavoriteClick(params.row)}
                sx={{ paddingLeft: 0 }}
              >
                {params.row.isFavorite ? (
                  <StarIcon color="secondary" />
                ) : (
                  <StarOutlineIcon />
                )}
              </IconButton>
              <Link
                component="button"
                sx={{
                  color: (theme: Theme) => theme.palette.info.main,
                  textDecorationColor: (theme: Theme) =>
                    theme.palette.info.main,
                }}
                onClick={() => handleNameClick(params.row)}
              >
                {params.row.name}
              </Link>
            </>
          ),
      },
      {
        field: "alias",
        type: "string",
        headerName: "Alias(es)",
        flex: 1,
      },
      {
        field: "groups",
        type: "string",
        headerName: "Group(s)",
        flex: 1,
        valueGetter: (value) => {
          const groups = (value || []) as unknown as DeviceGroup[];
          return groups.map((obj) => obj.name).join(", ");
        },
      },
      {
        field: "siteName",
        type: "string",
        headerName: "Site",
        flex: 1,
      },
      { field: "type", type: "string", headerName: "Type", flex: 1 },
      {
        field: "accessMethod",
        type: "string",
        headerName: "Access Method",
        flex: 1,
        renderCell: (params) => (
          <React.Fragment
            key={`${params.row.accessMethod}${params.row.deviceId}`}
          >
            {params.row.accessStatus !== RuleStatus.IN_ACCESSIBLE ? (
              <Link
                component="button"
                sx={{
                  color: (theme) => theme.palette.info.main,
                  textDecorationColor: (theme) => theme.palette.info.main,
                }}
                onClick={() =>
                  handleAccessMethodClick(params.row.accessMethod, params.row)
                }
              >
                {params.row.accessMethod}
              </Link>
            ) : (
              <Link component="button">{params.row.accessMethod}</Link>
            )}
            &nbsp;
          </React.Fragment>
        ),
      },
      {
        field: "manufacturerName",
        type: "string",
        headerName: "Manufacturer",
        flex: 1,
      },
      {
        field: "accessWindow",
        type: "string",
        headerName: "Access Window",
        width: 400,
        sortable: false,
        display: "flex",
        renderCell: (params) => {
          const rules = (params.row.rules || []) as DeviceRule[];
          return renderRuleAccessWindowCellContent(rules);
        },
      },
      {
        field: "actions",
        headerName: "Actions",
        type: "actions",
        flex: 1,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon color="info" />}
            label="Edit"
            disabled={!hasPermission("devices.summary", "write")}
            onClick={editDevice(params)}
            showInMenu
            key="editDevice"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon color="info" />}
            label="Delete"
            disabled={!hasPermission("devices.summary", "delete")}
            onClick={deleteDevice(params)}
            showInMenu
            key="deleteDevice"
          />,
        ],
      },
    ],
    [
      deleteDevice,
      editDevice,
      handleAccessMethodClick,
      handleFavoriteClick,
      handleNameClick,
      props.showAddGroupForm,
    ]
  );
  // Filter columns based on user role
  const filteredColumns = isEndUser()
    ? columns.filter(
        (column) => column.field !== "actions" && column.field !== "groups"
      )
    : columns.filter((column) => column.field !== "accessWindow");

  const handleFilterClick = () => {
    setOpenFilter(true);
  };
  const handleApplyFilter = (filterValues: DevicesGroupFilterData) => {
    setFilters(filterValues);
    const filteredDevices = getFilteredDeviceRows(props.data, filterValues);
    setFilteredRows(filteredDevices);
    getSortedRows(filteredDevices, selectedSortOption);
    setFilterSubmitted(true);
  };

  const handleClearFilter = () => {
    setFilteredRows(props.data);
    getSortedRows(props.data, selectedSortOption);
    setFilterSubmitted(false);
  };

  const handleCancelFilter = () => {
    setFilteredRows(props.data);
    getSortedRows(props.data, selectedSortOption);
    setOpenFilter(false);
    setFilters(defaultFilterValues);
    setFilterSubmitted(false);
    if (props.showAddGroupForm) {
      //reset row selection on cancel
      setRowSelectionModel([]);
      props.hideGroupForm();
    }
  };

  const handleCloseModal = () => {
    setOpenDetailsModal(false);
  };

  const onCancelGroup = () => {
    getSortedRows(props.data, selectedSortOption);
    setFilteredRows(props.data);
    setFilters(defaultFilterValues);
    setOpenFilter(false);
    //reset row selection on cancel
    setRowSelectionModel([]);
    props.hideGroupForm();
  };

  const onSaveGroup = (data: DeviceGroupNameForm) => {
    if (rowSelectionModel.length > 0) {
      setDeviceGroupName(data.name);
      //get type confirmation
      setOpenDeviceTypeDialogue(true);
    } else {
      dispatch(
        setSnackbarToast({
          message: "Please select the devices",
          open: true,
          severity: "error",
        })
      );
    }
  };

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const [openDeviceTypeDialogue, setOpenDeviceTypeDialogue] = useState(false);

  const onConfirmType = async () => {
    const deviceGroup: CreateDeviceGroup = {
      name: deviceGroupName,
      type: groupTypeSwitchValue,
      devicesCount: rowSelectionModel.length,
      accessMethods: filters.accessMethods,
      devices: rowSelectionModel as string[],
      deviceStatuses: filters.statuses,
      deviceTypes: filters.types,
      interfaces: filters.interfaces,
      manufacturers: filters.manufacturers,
      sites: filters.sites,
    };
    //save group
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const groupResponse = await apiClient.post(
        `/devices-groups`,
        deviceGroup
      );
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      dispatch(
        setSnackbarToast({
          message: groupResponse.data.meta.message,
          open: true,
          severity: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
    setOpenDeviceTypeDialogue(false);
    props.navigateToGroups();
  };

  useEffect(() => {
    if (props.showAddGroupForm) {
      setRows(props.data);
      setFilters(defaultFilterValues);
      setOpenFilter(true);
    }
  }, [defaultFilterValues, getSortedRows, props.data, props.showAddGroupForm]);

  const [groupTypeSwitchValue, setGroupTypeSwitchValue] = useState(
    DeviceGroupType.STATIC
  );
  const onCancelGroupType = (type: DeviceGroupType) => {
    setGroupTypeSwitchValue(type);
  };

  const onConfirmSaveGroupType = (type: DeviceGroupType) => {
    if (type === DeviceGroupType.DYNAMIC) {
      const updatedRowsSelection = rows.map((row) => row.deviceId);
      setRowSelectionModel(updatedRowsSelection);
    }
    setGroupTypeSwitchValue(type);
  };

  const handleSortOptionChange = (selectedValue: FilterDisplayOption) => {
    setSelectedSortOption(selectedValue);
    getSortedRows([...filteredRows], selectedValue);
  };

  return (
    <>
      {props.showAddGroupForm && (
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <AddDeviceGroup
              onCancelGroup={onCancelGroup}
              onSaveGroup={onSaveGroup}
            ></AddDeviceGroup>
          </Grid>
          <Grid item xs={3} alignContent={"center"}>
            <GroupType
              rows={rows}
              rowSelectionModel={rowSelectionModel as string[]}
              onCancel={onCancelGroupType}
              defaultGroupType={groupTypeSwitchValue}
              onConfirmSave={onConfirmSaveGroupType}
              filters={filters}
              isFilterSubmitted={isFilterSubmitted}
            ></GroupType>
          </Grid>
        </Grid>
      )}

      {openFilter && (
        <Card elevation={0} className={classes.contentSection}>
          <DevicesFilter
            onApplyFilter={handleApplyFilter}
            handleCancelFilter={handleCancelFilter}
            handleClearFilter={handleClearFilter}
            filters={filters}
            isResetForm={props.showAddGroupForm}
            isShowCancel={!props.showAddGroupForm}
            deviceFormMasterData={props.deviceFormMasterData}
          />
        </Card>
      )}
      <DataGrid
        disableVirtualization
        columns={filteredColumns}
        rows={rows}
        getRowId={(row) => row.deviceId}
        pageSizeOptions={constants.PAGE_SIZE_OPTIONS}
        initialState={{
          pagination: { paginationModel: constants.PAGINATION_MODEL },
          columns: {
            columnVisibilityModel: { alias: !isEndUser() },
          },
          filter: { filterModel: { items: [], quickFilterValues: [] } },
        }}
        slots={{
          toolbar: () => (
            <CustomToolbar
              handleFilterClick={handleFilterClick}
              isDisplaySortOptions
              handleSortOptionChange={(selectedOption) =>
                handleSortOptionChange(selectedOption)
              }
              sortOptionValue={selectedSortOption}
            />
          ),
          noRowsOverlay: () => (
            <NoRowsOverlay
              hasAccess={hasPermission("devices.summary", "read")}
              name="Devices"
            />
          ),
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        autoHeight
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "600",
          },
        }}
        getRowClassName={(params) => {
          if (isEndUser()) {
            return params.row.accessStatus === RuleStatus.IN_ACCESSIBLE
              ? classes.disabledRow
              : "";
          }
          return params.row.type.toLowerCase() === constants.DEVICE_TYPE_GATEWAY
            ? classes.rowColor
            : "";
        }}
        isRowSelectable={() => groupTypeSwitchValue === DeviceGroupType.STATIC}
        checkboxSelection={props.showAddGroupForm}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        disableRowSelectionOnClick
      />

      <Dialog
        open={openDetailsModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}></DialogTitle>
        <IconButton
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            right: 10,
            top: 8,
            color: (theme: Theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className={classes.dialogModal}>
          <GatewayDeviceDetails device={selectedDevice}></GatewayDeviceDetails>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            variant="outlined"
            color="info"
            sx={{
              right: 20,
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {openDeviceTypeDialogue && (
        <DeviceTypeDialog
          dialogContent={
            groupTypeSwitchValue === DeviceGroupType.STATIC
              ? constants.STATIC_GROUP_CONFIRM_MESSAGE
              : constants.DYNAMIC_GROUP_CONFIRM_MESSAGE
          }
          onConfirmType={onConfirmType}
          open={openDeviceTypeDialogue}
          onCancel={() => {
            setOpenDeviceTypeDialogue(false);
          }}
        ></DeviceTypeDialog>
      )}
      {openSSHAccessModal && (
        <RemoteAccessSSH
          open={openSSHAccessModal}
          onClose={handleCloseSSHAccessModal}
          device={selectedDevice}
        />
      )}
      {openRDPAccessModal && (
        <RemoteAccessRDP
          open={openRDPAccessModal}
          onClose={handleCloseRDPAccessModal}
          device={selectedDevice}
        />
      )}
      {openVNCAccessModal && (
        <RemoteAccessVNC
          open={openVNCAccessModal}
          onClose={handleCloseVNCAccessModal}
          device={selectedDevice}
        />
      )}
      <VideoModal
        open={openVideoModal}
        onClose={handleCloseVideoModal}
        videoUrl={videoUrl}
      />
    </>
  );
};
export default DevicesTable;
