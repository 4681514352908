import React, { useCallback, useEffect, useRef, useState } from "react";

import EditIcon from "@mui/icons-material/EditLocation";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  CssBaseline,
  Card,
  Drawer,
  Typography,
  Grid,
  Button,
  CardContent,
  CardHeader,
  Breadcrumbs,
  Link as MuiLink,
  IconButton,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Device, DeviceDetails, DeviceFormMasterData } from "../types";
import SiteMap from "common/components/SiteMap";
import EditDevice from "../devicesList/EditDevice";
import apiClient from "common/apiClientAxios";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import { hasPermission, isEndUser } from "common/helpers/utils";
import { DrawerHeader } from "common/styles/styledComponents";
import {
  DeviceAccessMethod,
  FeatureStatus,
  RemoteAccessType,
} from "common/enums";
import VideoModal from "common/components/VideoModal";
import assets from "../../../assets";
import { constants } from "common/constants";
import { FeatureSetting } from "pages/settings/types";
import RemoteAccessSSH from "../remoteAccess/RemoteAccessSSH";
import RemoteAccessRDP from "../remoteAccess/RemoteAccessRDP";
import { deviceDefaultValues } from "../devicesList/Devices";
import AddManufacturerDialog from "../devicesList/AddManufacturerDialog";
import RemoteAccessVNC from "../remoteAccess/RemoteAccessVNC";
import { User } from "pages/users/types";

export const useStyles = makeStyles(() => ({
  formPaper: {
    width: "40%",
  },
  contentSection: {
    padding: "4px",
    marginTop: "10px",
  },
  contentPadding: {
    padding: "2px",
  },
  value: {
    wordWrap: "break-word",
    overflowWrap: "break-word",
  },
  cardHeader: {
    fontSize: "20px",
    fontWeight: "normal",
  },
}));

const DeviceSummaryTab: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { device, deviceFormMasterData: deviceFormMasterDataLocationState } =
    (location.state as {
      device: Device;
      deviceFormMasterData: DeviceFormMasterData;
    }) || {};
  const [isEdit, setEdit] = useState(false);
  const [deviceDetails, setDeviceDetails] = useState<DeviceDetails>(
    device || { ...deviceDefaultValues }
  );
  const [isRemoteAccess, setRemoteAccess] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const [openSSHAccessModal, setOpenSSHAccessModal] = useState(false);
  const [openRDPAccessModal, setOpenRDPAccessModal] = useState(false);
  const [openVNCAccessModal, setOpenVNCAccessModal] = useState(false);

  const onCloseEdit = () => {
    setEdit(false);
  };
  const onSaveDevice = () => {
    setEdit(false);
    getDeviceDetails();
  };

  const openEditForm = () => {
    setEdit(true);
  };

  const isRunEffect = useRef(true);
  const getDeviceDetails = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const detailsResponse = await apiClient.get(`devices/${device.deviceId}`);
      const details = detailsResponse.data.data as DeviceDetails;
      setDeviceDetails(details);
      //If feature is code_devices.remote_access, then show access modal else show video modal
      const featuresResponse = await apiClient.get("/settings/features");
      const featureList = featuresResponse.data.data as FeatureSetting[];
      const remoteAccessSettings = featureList.find(
        (feature) => feature.featureId === "code_devices.remote_access"
      );
      setRemoteAccess(remoteAccessSettings?.status === FeatureStatus.ENABLED);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  }, [device.deviceId, dispatch]);

  const updateLastAccess = useCallback(async () => {
    await apiClient.put(`devices/${device.deviceId}/updateLastAccess`);
  }, [device.deviceId]);

  useEffect(() => {
    if (!device?.deviceId) {
      dispatch(
        setSnackbarToast({
          message: "Please select device from list",
          open: true,
          severity: "error",
        })
      );
      navigate("/portal/devices/summary");
    }
    if (isRunEffect.current) {
      getDeviceDetails();
      updateLastAccess();
      isRunEffect.current = false;
    }
  }, [
    device,
    dispatch,
    getDeviceDetails,
    isRunEffect,
    navigate,
    updateLastAccess,
  ]);

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  const handleCloseSSHAccessModal = () => {
    setOpenSSHAccessModal(false);
  };

  const handleCloseRDPAccessModal = () => {
    setOpenRDPAccessModal(false);
  };
  const handleCloseVNCAccessModal = () => {
    setOpenVNCAccessModal(false);
  };

  const handleRemoteAccessType = (accessMethod: DeviceAccessMethod) => {
    const loggedInUser = JSON.parse(
      localStorage.getItem("user") ?? "{}"
    ) as User;
    const remoteAccessType =
      (loggedInUser?.deviceAccessPreference as RemoteAccessType) ??
      RemoteAccessType.DIALOG;
    if (remoteAccessType !== RemoteAccessType.DIALOG) {
      localStorage.setItem("remote_access", JSON.stringify(device));
      openNewTab(accessMethod);
    } else {
      handleDialogAccess(accessMethod);
    }
  };

  const openNewTab = (accessMethod: DeviceAccessMethod) => {
    const url = `/devices/remoteAccess/${device.deviceId}/${accessMethod}`;
    const newTab = window.open(url, "_blank");
    if (newTab) {
      newTab.focus();
    } else {
      console.error(
        "Unable to open a new tab. Make sure pop-ups are allowed in the browser."
      );
    }
  };

  const handleDialogAccess = (accessMethod: DeviceAccessMethod) => {
    switch (accessMethod.toLocaleLowerCase()) {
      case DeviceAccessMethod.SSH:
        setOpenSSHAccessModal(true);
        break;
      case DeviceAccessMethod.RDP:
        setOpenRDPAccessModal(true);
        break;
      case DeviceAccessMethod.HTTP:
      case DeviceAccessMethod.HTTPS:
        setOpenVNCAccessModal(true);
        break;
      default:
        break;
    }
  };

  const handleLocalAccess = (accessMethod: DeviceAccessMethod) => {
    setVideoUrl(
      accessMethod === DeviceAccessMethod.SSH
        ? assets.videos.SSHVideo
        : assets.videos.RDPSCADA
    );
    setOpenVideoModal(true);
  };

  const handleAccessClick = (accessMethod: DeviceAccessMethod) => {
    if (isRemoteAccess) {
      if (!device.siteId) {
        dispatch(
          setSnackbarToast({
            message: constants.DEVICE_SITE_MISSING,
            open: true,
            severity: "error",
          })
        );
      } else handleRemoteAccessType(accessMethod);
    } else {
      handleLocalAccess(accessMethod);
    }
  };

  const [deviceFormMasterData, setDeviceFormMasterData] =
    useState<DeviceFormMasterData>(
      deviceFormMasterDataLocationState ?? {
        accessMethods: [],
        deviceTypes: [],
        groups: [],
        manufacturers: [],
        sites: [],
      }
    );
  const [openAddManufacturerDialogue, setOpenAddManufacturerDialogue] =
    React.useState(false);
  const getManufacturers = async () => {
    try {
      const manufacturersResponse = await apiClient.get("/manufacturers");
      setDeviceFormMasterData((prevState) => ({
        ...prevState,
        manufacturers: manufacturersResponse.data.data,
      }));
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
    setOpenAddManufacturerDialogue(false);
  };

  const handleFavoriteDevice = async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      if (deviceDetails.isFavorite) {
        await apiClient.delete(
          `users/favoriteDevice/${deviceDetails.deviceId}`
        );
      } else {
        await apiClient.put(`users/favoriteDevice/${deviceDetails.deviceId}`);
      }
      const detailsResponse = await apiClient.get(`devices/${device.deviceId}`);
      const details = detailsResponse.data.data as DeviceDetails;
      setDeviceDetails(details);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
  };
  const theme = useTheme();
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <MuiLink underline="none" color="inherit">
            Devices
          </MuiLink>
          <Link
            to="/portal/devices/summary"
            style={{
              color: theme.palette.text.secondary,
            }}
          >
            List
          </Link>
          <Typography variant="body1">{deviceDetails.name}</Typography>
        </Breadcrumbs>
      </div>
      <Card elevation={0}>
        <CardHeader
          disableTypography
          title={deviceDetails.name}
          className={classes.cardHeader}
          action={
            <Grid container spacing={2}>
              <Grid item>
                <IconButton onClick={handleFavoriteDevice}>
                  {deviceDetails.isFavorite ? (
                    <StarIcon color="secondary" />
                  ) : (
                    <StarOutlineIcon />
                  )}
                </IconButton>
              </Grid>
              {!isEndUser() && hasPermission("devices.summary", "write") && (
                <Grid item>
                  <Button
                    onClick={openEditForm}
                    color="info"
                    startIcon={<EditIcon />}
                  >
                    {"Edit Device"}
                  </Button>
                </Grid>
              )}
              {!isEndUser() && (
                <Grid item>
                  <Button color="info" startIcon={<FileDownloadIcon />}>
                    {"Export Device"}
                  </Button>
                </Grid>
              )}
            </Grid>
          }
        ></CardHeader>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardHeader
                    title="Layout"
                    className={classes.cardHeader}
                    disableTypography
                  />
                  {deviceDetails.latitude && deviceDetails.longitude ? (
                    <SiteMap
                      latitude={parseFloat(deviceDetails.latitude)}
                      longitude={parseFloat(deviceDetails.longitude)}
                    ></SiteMap>
                  ) : (
                    <div>
                      <p>Device location is not provided</p>
                    </div>
                  )}
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                container
                justifyContent={"space-between"}
                spacing={1}
              >
                <Grid item xs={3}>
                  <Button
                    size="small"
                    fullWidth
                    variant="contained"
                    disabled={
                      deviceDetails.accessMethod.toLocaleLowerCase() !==
                      DeviceAccessMethod.SSH
                    }
                    color="info"
                    onClick={() => handleAccessClick(DeviceAccessMethod.SSH)}
                  >
                    {"SSH Access"}
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    size="small"
                    fullWidth
                    variant="contained"
                    disabled={
                      deviceDetails.accessMethod.toLocaleLowerCase() !==
                      DeviceAccessMethod.HTTP
                    }
                    color="info"
                    onClick={() => handleAccessClick(DeviceAccessMethod.HTTP)}
                  >
                    {"HTTP Access"}
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    size="small"
                    fullWidth
                    variant="contained"
                    disabled={
                      deviceDetails.accessMethod.toLocaleLowerCase() !==
                      DeviceAccessMethod.RDP
                    }
                    color="info"
                    onClick={() => handleAccessClick(DeviceAccessMethod.RDP)}
                  >
                    {"RDP Access"}
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    size="small"
                    fullWidth
                    variant="contained"
                    disabled={
                      deviceDetails.accessMethod.toLocaleLowerCase() !==
                      DeviceAccessMethod.CONFIG
                    }
                    color="info"
                  >
                    {"Config Access"}
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    size="small"
                    fullWidth
                    variant="contained"
                    disabled={
                      deviceDetails.accessMethod.toLocaleLowerCase() !==
                      DeviceAccessMethod.HTTPS
                    }
                    color="info"
                    onClick={() => handleAccessClick(DeviceAccessMethod.HTTPS)}
                  >
                    {"HTTPS Access"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardHeader
                    title="Device Information"
                    className={classes.cardHeader}
                    disableTypography
                    sx={{ paddingBottom: "0px" }}
                  />
                  <CardContent>
                    <Grid container>
                      <Grid item xs={5}>
                        <Typography>Alias(es)</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="body2">
                          {deviceDetails.alias ?? "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Site</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="body2">
                          {deviceDetails.siteName}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Local IP Address</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="body2">
                          {deviceDetails.ipAddress ?? "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Type</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="body2">
                          {deviceDetails.type ?? "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Access Method</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="body2">
                          {deviceDetails.accessMethod ?? "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>MAC Address</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="body2">
                          {deviceDetails.macAddress ?? "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Manufacturer</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="body2">
                          {deviceDetails.manufacturerName ?? "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardHeader
                    title="Group Access Policies"
                    className={classes.cardHeader}
                    disableTypography
                    sx={{ paddingBottom: "0px" }}
                  />
                  <CardContent>
                    <Grid container>
                      {deviceDetails.groups &&
                      deviceDetails.groups.length > 0 ? (
                        deviceDetails.groups.map((group) => (
                          <React.Fragment key={group.groupId}>
                            <Grid item xs={5}>
                              <Typography>{group.name}</Typography>
                            </Grid>
                            <Grid item xs={7}>
                              <Typography variant="body2">
                                <MuiLink>view</MuiLink>
                              </Typography>
                            </Grid>
                          </React.Fragment>
                        ))
                      ) : (
                        <Typography variant="body2">
                          No groups are assigned
                        </Typography>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Card variant="outlined">
              <CardHeader
                title="Site Information"
                className={classes.cardHeader}
                disableTypography
                sx={{ paddingBottom: "0px" }}
              />
              <CardContent>
                <Grid container>
                  <Grid item xs={5}>
                    <Typography>Name</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body2">
                      {deviceDetails.site?.name ?? "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>Alias(es)</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body2">
                      {deviceDetails.site?.alias ?? "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>Owner</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body2">
                      {deviceDetails.site?.ownerName ?? "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>Operator</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body2">
                      {deviceDetails.site?.operatorName ?? "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>City</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body2">
                      {deviceDetails.site?.city ?? "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>State</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body2">
                      {deviceDetails.site?.state ?? "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>Country</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body2">
                      {deviceDetails.site?.country ?? "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Drawer
          anchor="right"
          open={isEdit}
          classes={{ paper: classes.formPaper }}
        >
          <DrawerHeader />
          <EditDevice
            onSaveUpdateTable={onSaveDevice}
            onClose={onCloseEdit}
            selectedDevice={deviceDetails}
            deviceFormMasterData={deviceFormMasterData}
            openAddManufacturerForm={() => setOpenAddManufacturerDialogue(true)}
          ></EditDevice>
        </Drawer>
      </Card>
      {openSSHAccessModal && (
        <RemoteAccessSSH
          open={openSSHAccessModal}
          onClose={handleCloseSSHAccessModal}
          device={deviceDetails}
        />
      )}
      {openRDPAccessModal && (
        <RemoteAccessRDP
          open={openRDPAccessModal}
          onClose={handleCloseRDPAccessModal}
          device={deviceDetails}
        />
      )}
      {openVNCAccessModal && (
        <RemoteAccessVNC
          open={openVNCAccessModal}
          onClose={handleCloseVNCAccessModal}
          device={deviceDetails}
        />
      )}

      <VideoModal
        open={openVideoModal}
        onClose={handleCloseVideoModal}
        videoUrl={videoUrl}
      />
      {openAddManufacturerDialogue && (
        <AddManufacturerDialog
          open={openAddManufacturerDialogue}
          onCancel={() => setOpenAddManufacturerDialogue(false)}
          onSave={getManufacturers}
        ></AddManufacturerDialog>
      )}
    </React.Fragment>
  );
};

export default DeviceSummaryTab;
