import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useContentStyles = makeStyles((theme: Theme) => ({
  formPaper: {
    width: "40%",
  },
  contentSection: {
    padding: "4px",
    // border: '1px solid #0000111E',
    marginTop: "10px",
  },
  contentPadding: {
    padding: "2px",
  },
  cardHeader: {
    fontSize: "20px",
    fontWeight: "normal",
  },
  rowColor: {
    backgroundColor: theme.palette.secondary.main,
  },
  dialogModal: {
    maxHeight: "750px",
    minHeight: "750px",
    padding: "4px",
  },
  value: {
    wordWrap: "break-word",
    overflowWrap: "break-word",
  },
  borderedTableContainer: {
    border: "1px solid #e5e7eb",
  },
  ulListItem: {
    listStyle: "inside",
    margin: "16px",
  },
  dialogListContainer: {
    maxHeight: "500px",
    overflowY: "auto",
  },
  disabledRow: {
    opacity: 0.6,
    // pointerEvents: 'none',
  },
}));
