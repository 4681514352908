export enum UserStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
  PENDING = "Pending",
}

export enum Recurrence {
  DAILY = "Daily",
  WEEKLY = "Weekly",
  MONTHLY = "Monthly",
  YEARLY = "Yearly",
}

export enum DeviceStatus {
  ONLINE = "Online",
  OFFLINE = "Offline",
}

export enum DeviceAccessMethod {
  CONFIG = "config",
  HTTP = "http",
  HTTPS = "https",
  RDP = "rdp",
  SSH = "ssh",
}

export enum IdentityAccessProvider {
  AZURE = "Azure",
  OKTA = "Okta",
  CUSTOM = "Custom",
  LOCAL_AD = "Local_AD",
}

export enum IdentityProviderStatus {
  ACTIVE = "Active",
  INACTIVE = "Inactive",
}

export enum FeatureStatus {
  ENABLED = "Enabled",
  DISABLED = "Disabled",
}

export enum DeviceGroupType {
  STATIC = "Static",
  DYNAMIC = "Dynamic",
}

export enum UserGroupType {
  STATIC = "Static",
  DYNAMIC = "Dynamic",
}

export enum DevicePort {
  SSH = "22",
  RDP = "3389",
  HTTP = "80",
  HTTPS = "443",
}

export enum LoginFeature {
  AZURE = "azure",
  CUSTOM = "custom",
  LOCAL_AD = "local_ad",
}

export enum FilterDisplayOption {
  ALL = "all",
  FAVORITE = "favorite",
  RECENT = "recent",
  ACCESS = "access",
}

export enum RuleStatus {
  ACTIVE = "Active",
  DISABLED = "Disabled",
  EXPIRED = "Expired",
  FUTURE = "Future",
  INCOMPLETE = "Incomplete",
  ACCESSIBLE = "Accessible",
  IN_ACCESSIBLE = "Inaccessible",
}

export enum RuleTemplate {
  ALL_DAYS = "All Days",
  WEEK_DAYS = "Week Days",
  CUSTOM = "Custom",
  FULL_DAY_TIME = "Full Day",
}

export enum RecurrenceUnit {
  DAYS = "Days",
  MONTHS = "Months",
  WEEKS = "Weeks",
  YEARS = "Years",
}

export enum AccessWindowType {
  DATE_RANGE = "DateRange",
  RECURRENCE = "Recurrence",
}

export enum PortalType {
  ALL = "ALL",
  ENDUSER = "ENDUSER",
}

export enum RemoteAccessType {
  DIALOG = "dialog",
  TAB = "newTab",
}
