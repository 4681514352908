import useAuth from "pages/login/authService";
import React from "react";

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { logout } = useAuth();
  const isAuthenticated =
    !!localStorage.getItem("access_token") && !!localStorage.getItem("user");
  if (!isAuthenticated) {
    logout();
  }
  return children;
};
export default ProtectedRoute;
