import React from "react";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FormProvider, useForm } from "react-hook-form";

import { UserGroupNameForm } from "../types";
import TcTextField from "common/components/TcTextField";
import { constants } from "common/constants";

type AddUserGroupProps = {
  onSaveGroup: (formData: UserGroupNameForm) => void;
  onCancelGroup: () => void;
  formData?: UserGroupNameForm;
  isEdit?: boolean;
};

const AddUserGroup: React.FC<AddUserGroupProps> = (props) => {
  const methods = useForm<UserGroupNameForm>({
    defaultValues: { name: props.formData?.name ?? "" },
    mode: "onBlur",
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const onSaveGroup = handleSubmit(async (data: UserGroupNameForm) => {
    props.onSaveGroup(data);
  });

  return (
    <React.Fragment>
      <FormProvider {...methods}>
        <form noValidate>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}></Grid>
            <Grid item>
              <Typography variant="body1">
                <strong>User Group:</strong>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <TcTextField
                name="name"
                variant="outlined"
                fullWidth
                rules={{
                  required: "Name is required",
                  pattern: {
                    value: constants.GROUP_NAME_REGEX,
                    message: "Invalid name",
                  },
                }}
              ></TcTextField>
            </Grid>
            <Grid item>
              <Button
                size="small"
                type="submit"
                disabled={!isValid}
                onClick={onSaveGroup}
                fullWidth
                variant="contained"
                color="info"
              >
                {props.isEdit ? "Update Group" : "Create Group"}
              </Button>
            </Grid>
            {/* <Grid item>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="info"
                onClick={onClose}>
                {'Cancel'}
              </Button>
            </Grid> */}
          </Grid>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};

export default AddUserGroup;
