import React, { useEffect, useState } from "react";
import { CssBaseline, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import GroupAdd from "@mui/icons-material/GroupAdd";

import apiClient from "common/apiClientAxios";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import { constants } from "../../../common/constants";
import { useDrawerFormStyles } from "common/styles/useDrawerFormStyles";
import { DeviceForm, DeviceFormMasterData, DeviceGroup } from "../types";
import TcTextField from "common/components/TcTextField";
import TcSelectDropdown from "common/components/TcSelectDropdown";
import { deviceDefaultValues } from "./Devices";
import {
  DeviceAccessMethod,
  DeviceGroupType,
  DeviceStatus,
} from "common/enums";
import { AccessMethod } from "pages/users/types";
import FormHeader from "common/components/FormHeader";
import SelectGroupDialog from "./SelectGroupDialog";
import { filterDeviceGroup } from "common/helpers/utils";
import TcSelectWithButtonLastOption from "common/components/TcSelectWithButtonLastOption";

type AddDeviceProps = {
  onClose: () => void;
  onSaveUpdateTable: () => void;
  deviceFormMasterData: DeviceFormMasterData;
  openAddManufacturerForm: () => void;
};

const AddDevice: React.FC<AddDeviceProps> = (props) => {
  const classes = useDrawerFormStyles();
  const dispatch = useDispatch();
  const { groups, deviceTypes, manufacturers, sites, accessMethods } =
    props.deviceFormMasterData;
  const onClose = () => {
    props.onClose();
  };

  const methods = useForm<DeviceForm>({
    defaultValues: deviceDefaultValues,
    mode: "onBlur",
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { isValid },
  } = methods;

  const [affectedDynamicGroups, setAffectedDynamicGroups] = useState<
    DeviceGroup[]
  >([]);
  const [submittedDeviceFormData, setSubmittedDeviceFormData] =
    useState<DeviceForm>({ ...deviceDefaultValues });
  const [openSelectGroupDialog, setOpenSelectGroupDialog] = useState(false);

  const onSaveDevice = handleSubmit(async (data: DeviceForm) => {
    setSubmittedDeviceFormData(data);
    const dynamicGroups = groups.filter(
      (group) => group.type === DeviceGroupType.DYNAMIC
    );
    const filterGroups = filterDeviceGroup(dynamicGroups, data);
    setAffectedDynamicGroups(filterGroups);
    if (filterGroups.length === 0) {
      submitDevice([], data);
    } else {
      setOpenSelectGroupDialog(true);
    }
  });

  const submitDevice = async (selectedGroups?: string[], data?: DeviceForm) => {
    try {
      const formData = data ?? submittedDeviceFormData;
      const deviceGroups = groups
        .filter((group) => selectedGroups?.includes(group.groupId))
        .map((obj) => {
          return { groupId: obj.groupId, name: obj.name };
        });
      const selectedType = deviceTypes.find(
        (deviceType) => deviceType.deviceTypeId === formData.typeId
      );
      const selectedManufacturer = manufacturers.find(
        (manufacturer) =>
          manufacturer.manufacturerId === formData.manufacturerId
      );
      const type = selectedType?.name;
      const manufacturerName = selectedManufacturer?.name;
      const site = sites.find((site) => site.siteId === formData.siteId);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        })
      );
      const deviceResponse = await apiClient.post(`/devices`, {
        ...formData,
        groups: deviceGroups,
        type,
        manufacturerName,
        siteName: site?.name,
        inboundIpAddr: site?.inboundIpAddr,
        status: DeviceStatus.OFFLINE, // static status TODO: remove
      });
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      dispatch(
        setSnackbarToast({
          message: deviceResponse.data.meta.message,
          open: true,
          severity: "success",
        })
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        })
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: "error",
        })
      );
    }
    props.onSaveUpdateTable();
  };

  const watchedTypeValue = watch("typeId");
  const [formAccessMethods, setFormAccessMethods] = useState<AccessMethod[]>(
    []
  );

  useEffect(() => {
    if (watchedTypeValue) {
      setValue("accessMethod", "");
      const type = deviceTypes.find(
        (deviceType) => deviceType.deviceTypeId === watchedTypeValue
      )?.name;
      if (type?.toLocaleLowerCase() !== constants.DEVICE_TYPE_GATEWAY) {
        setFormAccessMethods(
          accessMethods.filter(
            (method) =>
              method.name.toLocaleLowerCase() !== DeviceAccessMethod.CONFIG
          )
        ); //remove config
      } else {
        setFormAccessMethods(
          accessMethods.filter(
            (method) =>
              method.name.toLocaleLowerCase() === DeviceAccessMethod.CONFIG
          )
        );
      }
    }
  }, [accessMethods, deviceTypes, setValue, watchedTypeValue]);

  const handleCreateManufacturer = () => {
    props.openAddManufacturerForm();
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <FormHeader title="Add Device" onClose={onClose}></FormHeader>
      <FormProvider {...methods}>
        <form noValidate>
          <Grid container spacing={2} className={classes.formContainer}>
            <Grid item xs={6}>
              <TcTextField
                name="name"
                label="Name *"
                rules={{
                  required: "Name is required",
                  pattern: {
                    value: constants.NAME_REGEX,
                    message: "Invalid name",
                  },
                }}
              ></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="alias"
                label="Alias"
                rules={{
                  pattern: {
                    value: constants.NAME_REGEX,
                    message: "Invalid Alias",
                  },
                }}
              ></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcSelectWithButtonLastOption
                name="manufacturerId"
                label="Manufacturer"
                optionKey="manufacturerId"
                options={manufacturers}
                optionLabel="name"
                buttonIcon={<GroupAdd />}
                buttonLabel="Add Manufacturer"
                optionButtonClick={handleCreateManufacturer}
              ></TcSelectWithButtonLastOption>
            </Grid>
            <Grid item xs={6}>
              <TcSelectDropdown
                name="siteId"
                label="Site *"
                options={sites}
                optionKey="siteId"
                optionLabel="name"
                rules={{
                  required: "Site is required",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="ipAddress"
                label="Local IP Address *"
                rules={{
                  required: "Local IP Address is required",
                  pattern: {
                    value: constants.IP_ADDRESS_REGEX,
                    message: "Invalid IP",
                  },
                }}
              ></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcSelectDropdown
                name="typeId"
                label="Type *"
                options={deviceTypes}
                optionKey="deviceTypeId"
                optionLabel="name"
                rules={{
                  required: "Type is required",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcSelectDropdown
                name="accessMethod"
                label="Access Method *"
                options={formAccessMethods}
                optionKey="name"
                optionLabel="name"
                rules={{
                  required: "Access Method is required",
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.bottomDivider}>
            <Grid item xs={2}>
              <Button
                size="small"
                type="submit"
                disabled={!isValid}
                onClick={onSaveDevice}
                fullWidth
                variant="contained"
                color="info"
              >
                {"Save"}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="info"
                onClick={onClose}
              >
                {"Cancel"}
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </form>
      </FormProvider>
      {openSelectGroupDialog && (
        <SelectGroupDialog
          groups={affectedDynamicGroups}
          onCancel={() => setOpenSelectGroupDialog(false)}
          onSaveGroup={(selectedGroups) => submitDevice(selectedGroups)}
          open={openSelectGroupDialog}
        ></SelectGroupDialog>
      )}
    </React.Fragment>
  );
};

export default AddDevice;
