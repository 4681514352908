import React, { useCallback, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Guacamole from "guacamole-common-js";
import $ from "jquery";
import { DeviceDetails, UsernameAndPasswordForm } from "pages/devices/types";
import apiClient from "common/apiClientAxios";
import { RDPConnectionSettings } from "common/types";
import { useDispatch } from "react-redux";
import { setLoader, setSnackbarToast } from "redux/UiStateSlice";
import { deviceAccessErrors } from "pages/devices/device-access-errors";
import RDPLoginForm from "./RDPLoginForm";
import { constants } from "common/constants";
import {
  getAdminAccessTime,
  getDeviceAccessEndTime,
  isEndUser,
} from "common/helpers/utils";

interface RemoteAccessRDPProps {
  open: boolean;
  onClose: () => void;
  device: DeviceDetails;
  isHideDialog?: boolean;
}
const RemoteAccessRDP: React.FC<RemoteAccessRDPProps> = ({
  open,
  onClose,
  device,
  isHideDialog = false,
}) => {
  const [guacamoleClient, setGuacamoleClient] = useState<Guacamole.Client>();
  const [guacamoleClientState, setGuacamoleClientState] = useState(-1);
  const [guacamoleTunnelState, setGuacamoleTunnelState] = useState(-1);
  const [gateId, setGateId] = useState(-1);
  const [inboundPort, setInboundPort] = useState(-1);
  const [guacamoleKeyboard] = useState<Guacamole.Keyboard>(
    new Guacamole.Keyboard(document)
  );
  const [showUsernamePasswordForm, setShowUsernamePasswordForm] =
    useState(true);
  const [errorMessage, setErrorMessage] = useState("Something went wrong...");
  const dispatch = useDispatch();

  const onSubmitCredentials = async (data: UsernameAndPasswordForm) => {
    setErrorMessage("");
    setShowUsernamePasswordForm(false);
    const isReconnect =
      guacamoleClientState === 5 || guacamoleTunnelState === 2;
    await getToken(
      {
        hostname: device.ipAddress,
        username: data.username,
        password: data.password,
        endTime: isEndUser()
          ? getDeviceAccessEndTime(
              device.rules?.[0].endTime ?? new Date().toISOString()
            )
          : getAdminAccessTime(),
        siteId: device.siteId,
      },
      isReconnect,
      inboundPort
    );
  };

  const connectToDevice = useCallback(
    (token: string, webSocketBaseURL: string) => {
      window.setTimeout(() => {
        const display = document.getElementById("RDPdisplay");
        const container = document.getElementById("container");
        const width = container?.clientWidth
          ? container?.clientWidth - constants.CANVAS_WIDTH_OFFSET
          : constants.DIALOG_CANVAS_WIDTH;
        const height = container?.clientHeight
          ? container?.clientHeight - constants.CANVAS_HEIGHT_OFFSET
          : constants.DIALOG_CANVAS_HEIGHT;
        if (display && token) {
          const guacTunnel = new Guacamole.WebSocketTunnel(
            `${webSocketBaseURL}/?token=${token}&width=${width}&height=${height}&`
          );
          const guac = new Guacamole.Client(guacTunnel);
          setGuacamoleClient(guac);
          const displayElement = guac.getDisplay().getElement();

          // Add client to display div
          const canvas = $(displayElement).find(`canvas`);
          canvas.css("z-index", "2");
          display?.appendChild(displayElement);

          // Mouse
          const mouse = new Guacamole.Mouse(guac.getDisplay().getElement());
          mouse.onmousedown = (state: Guacamole.Mouse.State) => {
            guac.sendMouseState(state);
          };
          mouse.onmousemove = (state: Guacamole.Mouse.State) => {
            guac.sendMouseState(state);
          };
          mouse.onmouseup = (state: Guacamole.Mouse.State) => {
            guac.sendMouseState(state);
          };
          // Keyboard
          guacamoleKeyboard.onkeydown = function (keysym) {
            guac.sendKeyEvent(1, keysym);
          };
          guacamoleKeyboard.onkeyup = function (keysym) {
            guac.sendKeyEvent(0, keysym);
          };

          guac.onstatechange = (state: Guacamole.Client.State) => {
            console.log("Guacamole Client State =", state);
            setGuacamoleClientState(state);
            if (state === 5) {
              if (guacamoleKeyboard) {
                guacamoleKeyboard.onkeyup = null;
                guacamoleKeyboard.onkeydown = null;
              }
              handleDisconnect();
              setShowUsernamePasswordForm(true);
            }
          };
          guacTunnel.onstatechange = (state: Guacamole.Tunnel.State) => {
            console.log("Guacamole Tunnel State =", state);
            setGuacamoleTunnelState(state);
            if (state === 2) {
              if (guacamoleKeyboard) {
                guacamoleKeyboard.onkeyup = null;
                guacamoleKeyboard.onkeydown = null;
              }
              handleDisconnect();
              setShowUsernamePasswordForm(true);
            }
          };
          const handleDisconnect = () => {
            if (display.hasChildNodes()) display?.removeChild(displayElement);
            // guacamoleKeyboard.onkeyup = null;
            // guacamoleKeyboard.onkeydown = null;
            guacTunnel?.state === Guacamole.Tunnel.State.OPEN &&
              guac.disconnect();
            guacTunnel?.state === Guacamole.Tunnel.State.OPEN &&
              guacTunnel.disconnect();
          };
          const handleError = (error: Guacamole.Status) => {
            setErrorMessage(
              deviceAccessErrors[error.code] ?? "Something went wrong..."
            );
            handleDisconnect();
          };
          // Error handler
          guac.onerror = function (error) {
            handleError(error);
          };
          guacTunnel.onerror = function (error) {
            handleError(error);
          };
          // Connect
          guac.connect("test");
          // Disconnect on close
          window.onunload = function () {
            handleDisconnect();
          };
        }
      }, 1000);
    },
    [guacamoleKeyboard]
  );

  const closeConnection = useCallback(
    async (gateId?: number, inboundPort?: number) => {
      if (gateId !== -1) {
        try {
          dispatch(
            setLoader({
              loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
              openLoader: true,
            })
          );
          await apiClient.post(`devices/${device.deviceId}/closeConnection`, {
            siteId: device.siteId,
            gateId: gateId,
            inboundPort: inboundPort,
          });
          setGateId(-1);
          setInboundPort(-1);
          dispatch(
            setLoader({
              loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
              openLoader: false,
            })
          );
        } catch (error: any) {
          dispatch(
            setLoader({
              loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
              openLoader: false,
            })
          );
          const errorData =
            error.response?.data?.meta?.message || String(error.message);
          dispatch(
            setSnackbarToast({
              message: errorData,
              open: true,
              severity: "error",
            })
          );
        }
      }
    },
    [device.deviceId, device.siteId, dispatch]
  );

  const getToken = useCallback(
    async (
      connectionSettings: RDPConnectionSettings,
      isReconnect: boolean,
      port: number
    ) => {
      try {
        dispatch(
          setLoader({
            loaderMessage: "Please wait connecting...",
            openLoader: true,
          })
        );
        const api = isReconnect
          ? `devices/${device.deviceId}/getRDPReconnectToken`
          : `devices/${device.deviceId}/getRDPToken`;
        const payload = isReconnect
          ? { ...connectionSettings, port }
          : connectionSettings;
        const tokenResponse = await apiClient.post(api, payload);
        const tokenResponseData = tokenResponse.data.data;
        const token = tokenResponseData.token;
        if (!isReconnect) {
          setGateId(tokenResponseData.gateId ?? -1);
          setInboundPort(tokenResponseData.inboundPort ?? -1);
        }
        dispatch(
          setLoader({
            loaderMessage: "Please wait connecting...",
            openLoader: false,
          })
        );
        //connect to guacamole server
        const webSocketBaseURL =
          process.env.REACT_APP_WEB_SOCKET_URL ?? "ws://localhost:8082";
        connectToDevice(token, webSocketBaseURL);
        return token;
      } catch (error: any) {
        dispatch(
          setLoader({
            loaderMessage: "Please wait connecting...",
            openLoader: false,
          })
        );
        const errorData =
          error.response?.data?.meta?.message || String(error.message);
        dispatch(
          setSnackbarToast({
            message: errorData,
            open: true,
            severity: "error",
          })
        );
        //if dialog then close
        if (!isHideDialog) {
          onClose();
        }
      }
    },
    [connectToDevice, device.deviceId, dispatch, isHideDialog, onClose]
  );

  const onDisconnect = async () => {
    if (guacamoleKeyboard) {
      guacamoleKeyboard.onkeyup = null;
      guacamoleKeyboard.onkeydown = null;
    }
    await closeConnection(gateId, inboundPort);
    guacamoleClientState === Guacamole.Tunnel.State.OPEN &&
      guacamoleClient?.disconnect();
    onClose();
  };

  React.useEffect(() => {
    const handleBeforeUnload = async (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "";
      try {
        await closeConnection(gateId, inboundPort);
      } catch (error) {
        console.error("Error while closing gate:", error);
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [closeConnection, gateId, inboundPort]);

  return isHideDialog ? (
    <Card sx={{ overflow: "auto", minHeight: "100vh" }}>
      <CardHeader
        sx={{ paddingTop: 1, paddingBottom: 0 }}
        //titleTypographyProps={{ fontSize: 18 }}
        titleTypographyProps={{ textAlign: 'center', fontSize: 18}}
        title={`Connect to device: '${device.name}' IP: ${device.ipAddress} by access method 'RDP'`}
        action={
          <Button
            size="small"
            variant="contained"
            color="info"
            onClick={onDisconnect}
          >
            {"Close"}
          </Button>
        }
      />
      <CardContent sx={{ minHeight: "100vh" }} id="container">
        <Grid container justifyContent="center" alignItems="center">
          {/* Loading State */}
          {(guacamoleTunnelState === 0 ||
            guacamoleClientState === 1 ||
            guacamoleClientState === 2) &&
            guacamoleTunnelState !== 2 && (
              <>
                <Grid item>
                  <CircularProgress />
                </Grid>
                <Grid item>
                  <div>Connecting...</div>
                </Grid>
              </>
            )}
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {/* Error Message */}
            {(guacamoleClientState === 5 || guacamoleTunnelState === 2) && (
              <Grid item xs={12}>
                <Typography color="error" align="center">
                  {errorMessage}
                </Typography>
              </Grid>
            )}
            {/* Login Form */}
            {showUsernamePasswordForm && (
              <RDPLoginForm
                handleSubmitForm={onSubmitCredentials}
                onDisconnect={onDisconnect}
              ></RDPLoginForm>
            )}
          </Grid>
          <Grid item xs={12}>
            <div id="RDPdisplay"></div>
          </Grid>
        </Grid>
      </CardContent>

    </Card>
  ) : (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        style: {
          minHeight: "90vh",
          minWidth: "80vw",
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 1, pb: 1.5 }} textAlign={"center"}>
        {`Connect to device: '${device.name}' IP: ${device.ipAddress} by access method 'RDP'`}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onDisconnect}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent id="container">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          style={{ minHeight: "100%" }}
        >
          {/* Loading State */}
          {(guacamoleTunnelState === 0 ||
            guacamoleClientState === 1 ||
            guacamoleClientState === 2) &&
            guacamoleTunnelState !== 2 && (
              <>
                <Grid item>
                  <CircularProgress />
                </Grid>
                <Grid item>
                  <div>Connecting...</div>
                </Grid>
              </>
            )}
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {/* Error Message */}
            {(guacamoleClientState === 5 || guacamoleTunnelState === 2) && (
              <Grid item xs={12}>
                <Typography color="error" align="center">
                  {errorMessage}
                </Typography>
              </Grid>
            )}
            {/* Login Form */}
            {showUsernamePasswordForm && (
              <RDPLoginForm
                handleSubmitForm={onSubmitCredentials}
                onDisconnect={onDisconnect}
              ></RDPLoginForm>
            )}
          </Grid>
          <Grid item xs={12}>
            <div id="RDPdisplay"></div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          color="info"
          onClick={onDisconnect}
        >
          {"Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default RemoteAccessRDP;
